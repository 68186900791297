import type { LanguageCode } from '@sevenrooms/core/api'
import { type CountryCode, type PromoCode, type MessageChannelOptions, MessageChannelOptionsEnum } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { getCountryData } from '@sevenrooms/core/locales'
import { useCheckoutFormSchema } from './Checkout.zod'

type CheckoutOptionalType = 'Required' | 'Optional' | 'Hidden'

export interface UseCheckoutFormProps {
  agreedCustomCheckoutPolicy: boolean
  agreedToAboveAgeConsentOn: boolean
  agreedToBookingPolicy: boolean
  agreedToReservationSmsOptIn: boolean
  agreedToVenueGroupMarketingOptIn: boolean
  agreedToVenueSpecificMarketingOptIn: boolean
  agreedToVenueSmsMarketingOptIn: boolean
  agreedToGroupBookingsPolicy: boolean
  agreedToTailoredCommunicationOptIn: boolean
  agreedToGdprDietaryOptIn: boolean
  birthdayDay: string | null
  birthdayMonth: string | null
  birthdayType: CheckoutOptionalType
  defaultMessageChannel: MessageChannelOptions | undefined
  emailAddress: string
  firstName: string
  gratuityPercentage: number | null
  hasAgeToConsent: boolean
  hasReCaptcha: boolean
  hasBillingAddress: boolean
  hasCustomCheckoutPolicy: boolean
  hasGdprPolicy: boolean
  lastName: string
  phoneNumber?: string
  phoneCountryCode: CountryCode
  postalCode: string
  postalCodeType: CheckoutOptionalType
  preferredLanguage: LanguageCode
  preferredMessagingChannel?: MessageChannelOptionsEnum
  reCaptcha?: string
  hasRequiredClientSelectableGratuity: boolean
  hasBookingPolicy: boolean
  hasCustomPaymentForm: boolean
  hasGroupBookingsPolicy: boolean
  isWhatsappEnabled: boolean
  clientId?: string
  promoCode?: PromoCode
  salutation: string | null
  salutationType: CheckoutOptionalType
  specialOccasionTags: string[]
  clientDietaryPreferenceTags: string[]
  partyDietaryPreferenceTags: string[]
  champagnePreferenceTags: string[]
  liquorPreferenceTags: string[]
  customQuestionAnswer: string
}

export function useCheckoutForm({
  agreedCustomCheckoutPolicy,
  agreedToAboveAgeConsentOn,
  agreedToBookingPolicy,
  agreedToReservationSmsOptIn,
  agreedToVenueGroupMarketingOptIn,
  agreedToVenueSpecificMarketingOptIn,
  agreedToVenueSmsMarketingOptIn,
  agreedToTailoredCommunicationOptIn,
  agreedToGdprDietaryOptIn,
  birthdayDay,
  birthdayMonth,
  birthdayType,
  defaultMessageChannel,
  emailAddress,
  firstName,
  gratuityPercentage,
  hasAgeToConsent,
  hasReCaptcha,
  hasCustomCheckoutPolicy,
  hasRequiredClientSelectableGratuity,
  hasBookingPolicy,
  isWhatsappEnabled,
  lastName,
  phoneNumber,
  phoneCountryCode,
  postalCode,
  postalCodeType,
  preferredLanguage,
  preferredMessagingChannel,
  clientId,
  promoCode,
  hasBillingAddress,
  hasCustomPaymentForm,
  salutation,
  salutationType,
  hasGroupBookingsPolicy,
  agreedToGroupBookingsPolicy,
  hasGdprPolicy,
  specialOccasionTags,
  clientDietaryPreferenceTags,
  partyDietaryPreferenceTags,
  champagnePreferenceTags,
  liquorPreferenceTags,
  customQuestionAnswer,
}: UseCheckoutFormProps) {
  const CheckoutFormSchema = useCheckoutFormSchema({
    birthdayType,
    hasReCaptcha,
    postalCodeType,
    hasAgeToConsent,
    hasCustomCheckoutPolicy,
    hasRequiredClientSelectableGratuity,
    hasBookingPolicy,
    hasBillingAddress,
    hasCustomPaymentForm,
    salutationType,
    hasGroupBookingsPolicy,
    hasGdprPolicy,
  })
  const phoneDialCode = getCountryData(phoneCountryCode)?.dialCode ?? ''

  return useForm(CheckoutFormSchema, {
    defaultValues: {
      agreedCustomCheckoutPolicy,
      agreedToAboveAgeConsentOn,
      agreedToBookingPolicy,
      agreedToReservationSmsOptIn,
      agreedToTailoredCommunicationOptIn,
      agreedToVenueGroupMarketingOptIn,
      agreedToVenueSpecificMarketingOptIn,
      agreedToVenueSmsMarketingOptIn,
      agreedToGroupBookingsPolicy,
      birthdayDay,
      birthdayMonth,
      emailAddress,
      firstName,
      gratuityPercentage,
      lastName,
      phoneCountryCode,
      phoneDialCode,
      phoneNumber,
      postalCode,
      preferredLanguage,
      preferredMessagingChannel:
        preferredMessagingChannel ||
        (isWhatsappEnabled && defaultMessageChannel ? MessageChannelOptionsEnum[defaultMessageChannel] || undefined : undefined),
      reCaptcha: null,
      socialMediaLoginSite: 'guest',
      clientId,
      promoCode,
      salutation,
      agreedToGdprDietaryOptIn,
      specialOccasionTags,
      clientDietaryPreferenceTags,
      partyDietaryPreferenceTags,
      champagnePreferenceTags,
      liquorPreferenceTags,
      customQuestionAnswer,
    },
    mode: 'onSubmit',
  })
}
