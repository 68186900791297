import type { ManageReservationCalendarLinks } from '@sevenrooms/core/domain'
import { VStack, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationDetailsCalendar } from './ReservationDetailsCalendar'

export interface ReservationDetailsHeaderProps {
  venueName: string
  reservationReferences?: string[]
  calendarLinks?: ManageReservationCalendarLinks
}

export function ReservationDetailsHeader({ reservationReferences, venueName, calendarLinks }: ReservationDetailsHeaderProps) {
  return (
    <VStack spacing="s">
      <HStack spacing="m" alignItems="center" justifyContent="space-between">
        <Text data-test="reservation-details-venue-name" textStyle="h1" overflowWrap="anywhere">
          {venueName}
        </Text>
        {calendarLinks && <ReservationDetailsCalendar data-test="reservation-details-calendar" calendarLinks={calendarLinks} />}
      </HStack>
      <div>
        {reservationReferences?.map((reservationReference, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={index} type="div" textStyle="body1" color="secondaryFont" data-test={`reservation-details-reference-${index}`}>
            {reservationReference}
          </Text>
        ))}
      </div>
    </VStack>
  )
}
