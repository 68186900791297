import { useState } from 'react'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { HTMLContent, GFL_ALLOWED_TAGS } from '@sevenrooms/core/ui-kit/core'
import { BasicButton, Checkbox, type RadioChoice, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Flex, HStack, Modal, ModalBody, ModalHeader, ModalTitle, Window, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface OrderAgreementTermSubOptionProps {
  field: Field<string | undefined>
  options: RadioChoice<string>[]
}

export interface OrderAgreementTermSingleProps {
  field: Field<boolean>
  term: string
  infoTitle?: string
  infoContent?: string
  dataTest?: string
  subOptions?: OrderAgreementTermSubOptionProps
}

export interface OrderAgreementTermProps {
  agreement: OrderAgreementTermSingleProps
  disabled?: boolean
}

export interface AgreementDetailModal {
  title: string
  content: string
}

export function AgreementDetailModal({ title, content }: AgreementDetailModal) {
  const { formatMessage } = useLocales()

  const isSmallScreen = useMaxWidthBreakpoint('s')
  const [showModal, setShowModal] = useState(false)

  return (
    <Flex ml="xs">
      <BasicButton fontSize="l" aria-label={formatMessage(commonMessages.additionalInformation)} onClick={() => setShowModal(true)}>
        <Icon name="VMSWeb-info" />
      </BasicButton>
      <Window active={showModal}>
        <Modal ariaLabel="Modal" width="100%" data-test="agreement-term-modal" minWidth={!isSmallScreen ? '480px' : '100%'}>
          <ModalHeader onClose={() => setShowModal(false)}>
            <ModalTitle title={title} />
          </ModalHeader>
          <ModalBody mb="lm" data-test="modal-body">
            <Text textStyle="body1">
              <HTMLContent content={content} allowedTags={GFL_ALLOWED_TAGS} />
            </Text>
          </ModalBody>
        </Modal>
      </Window>
    </Flex>
  )
}

export function AgreementTerm({ agreement, disabled }: OrderAgreementTermProps) {
  const fieldValue = useWatch(agreement.field)
  return (
    <VStack key={agreement.term}>
      <HStack justifyContent="space-between">
        <Checkbox
          field={agreement.field}
          data-test={agreement.dataTest}
          disabled={disabled}
          extra={
            agreement.infoTitle && agreement.infoContent ? (
              <AgreementDetailModal title={agreement.infoTitle ?? ''} content={agreement.infoContent} />
            ) : undefined
          }
        >
          <HTMLContent content={agreement.term} allowedTags={GFL_ALLOWED_TAGS} />
          {agreement.subOptions && fieldValue && (
            <VStack pt="m">
              <RadioGroup
                field={agreement.subOptions.field}
                choices={agreement.subOptions.options}
                disabled={disabled}
                data-test={`${agreement.dataTest}-suboptions`}
              />
            </VStack>
          )}
        </Checkbox>
      </HStack>
    </VStack>
  )
}
