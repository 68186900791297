import { useMemo } from 'react'
import { useVenue } from '../../hooks'
import { useGetGuestFacingUpgradeQuery, useValidatePromoCodeMutation, useReservationFormState } from '../../store'
import { getTimeSlotTotals, getUpgradeTotals, getSelectedUpgrades, getIncludedUpgrades } from './getCheckoutTotals'

export const useCheckoutTotals = () => {
  const { id: venueId, taxGroups } = useVenue()
  const {
    formState: {
      partySize: timeSlotPartySize,
      privateEventsPartySize,
      selectedTimeSlot,
      selectedExperience,
      categories,
      gratuityPercentage: clientSelectedGratuity,
      redemptionData,
    },
  } = useReservationFormState()
  const partySize = selectedExperience && privateEventsPartySize !== null ? privateEventsPartySize : timeSlotPartySize
  const { data: upgradesData } = useGetGuestFacingUpgradeQuery(venueId)
  const [, { data: { promoCode } = { promoCode: undefined } }] = useValidatePromoCodeMutation({
    fixedCacheKey: 'validate-promo-code',
  })

  const {
    timeSlotBaseAmount = 0,
    timeSlotPromoDiscount = 0,
    timeSlotServiceCharge = 0,
    timeSlotTax = 0,
    timeSlotGratuity = 0,
    timeSlotSelectedGratuity,
  } = useMemo(
    () => getTimeSlotTotals({ selectedTimeSlot, partySize, promoCode, clientSelectedGratuity }),
    [selectedTimeSlot, partySize, promoCode, clientSelectedGratuity]
  )

  const {
    selectedUpgrades,
    selectedUpsellsBaseAmount,
    selectedUpsellsPromoDiscount,
    selectedUpsellsServiceCharge,
    selectedUpsellsTax,
    selectedUpsellsGratuity,
    selectedUpsellsSelectedGratuity,
    selectedUpsellsHasSelectableGratuity,
  } = useMemo(() => {
    const selectedUpgrades = getSelectedUpgrades({ categories, inventories: upgradesData?.inventories ?? [] })
    const {
      upsellsBaseAmount: selectedUpsellsBaseAmount,
      upsellsPromoDiscount: selectedUpsellsPromoDiscount,
      upsellsServiceCharge: selectedUpsellsServiceCharge,
      upsellsTax: selectedUpsellsTax,
      upsellsGratuity: selectedUpsellsGratuity,
      upsellsSelectedGratuity: selectedUpsellsSelectedGratuity,
      hasSelectableGratuity: selectedUpsellsHasSelectableGratuity,
    } = getUpgradeTotals({
      upgrades: selectedUpgrades,
      taxGroups,
      defaultServiceCharge: selectedTimeSlot?.defaultServiceCharge,
      usedPromoAmount: timeSlotPromoDiscount,
      clientSelectedGratuity,
      defaultGratuity: selectedTimeSlot?.defaultGratuity,
      promoCode,
      isRequiredUpgrade: false,
    })
    return {
      selectedUpgrades,
      selectedUpsellsBaseAmount,
      selectedUpsellsPromoDiscount,
      selectedUpsellsServiceCharge,
      selectedUpsellsTax,
      selectedUpsellsGratuity,
      selectedUpsellsSelectedGratuity,
      selectedUpsellsHasSelectableGratuity,
    }
  }, [
    categories,
    selectedTimeSlot?.defaultGratuity,
    upgradesData?.inventories,
    timeSlotPromoDiscount,
    promoCode,
    selectedTimeSlot?.defaultServiceCharge,
    taxGroups,
    clientSelectedGratuity,
  ])

  const {
    includedUpgrades,
    includedUpsellsBaseAmount = 0,
    includedUpsellsPromoDiscount = 0,
    includedUpsellsServiceCharge = 0,
    includedUpsellsTax = 0,
    includedUpsellsGratuity = 0,
    includedUpsellsSelectedGratuity = 0,
    includedUpsellsHasSelectableGratuity = false,
  } = useMemo(() => {
    if (!partySize) {
      return {}
    }
    const includedUpgrades = getIncludedUpgrades({
      partySize,
      categories: upgradesData?.categories,
      inventories: upgradesData?.inventories,
      upgrades: selectedTimeSlot?.selectedAutomaticUpsells,
    })
    const {
      upsellsBaseAmount: includedUpsellsBaseAmount,
      upsellsPromoDiscount: includedUpsellsPromoDiscount,
      upsellsServiceCharge: includedUpsellsServiceCharge,
      upsellsTax: includedUpsellsTax,
      upsellsGratuity: includedUpsellsGratuity,
      upsellsSelectedGratuity: includedUpsellsSelectedGratuity,
      hasSelectableGratuity: includedUpsellsHasSelectableGratuity,
    } = getUpgradeTotals({
      upgrades: includedUpgrades,
      taxGroups,
      defaultServiceCharge: selectedTimeSlot?.defaultServiceCharge,
      usedPromoAmount: (timeSlotPromoDiscount ?? 0) + selectedUpsellsPromoDiscount,
      clientSelectedGratuity,
      defaultGratuity: selectedTimeSlot?.defaultGratuity,
      promoCode,
      isRequiredUpgrade: true,
    })
    return {
      includedUpgrades,
      includedUpsellsBaseAmount,
      includedUpsellsPromoDiscount,
      includedUpsellsServiceCharge,
      includedUpsellsTax,
      includedUpsellsGratuity,
      includedUpsellsSelectedGratuity,
      includedUpsellsHasSelectableGratuity,
    }
  }, [
    partySize,
    selectedTimeSlot?.selectedAutomaticUpsells,
    upgradesData,
    promoCode,
    timeSlotPromoDiscount,
    selectedTimeSlot?.defaultServiceCharge,
    taxGroups,
    selectedUpsellsPromoDiscount,
    selectedTimeSlot?.defaultGratuity,
    clientSelectedGratuity,
  ])

  const serviceChargeAmount = timeSlotServiceCharge + selectedUpsellsServiceCharge + includedUpsellsServiceCharge
  const upsellAmount = selectedUpsellsBaseAmount
  const subtotalAmount = timeSlotBaseAmount + upsellAmount + includedUpsellsBaseAmount
  const promoCodeDiscountAmount = timeSlotPromoDiscount + selectedUpsellsPromoDiscount + includedUpsellsPromoDiscount
  const upgradesAmounts = useMemo(() => [...selectedUpgrades, ...(includedUpgrades ?? [])], [selectedUpgrades, includedUpgrades])
  const selectedGratuityAmount = (timeSlotSelectedGratuity ?? 0) + includedUpsellsSelectedGratuity + selectedUpsellsSelectedGratuity
  const gratuityAmount = timeSlotGratuity + selectedGratuityAmount + includedUpsellsGratuity + selectedUpsellsGratuity
  const taxAmount = timeSlotTax + selectedUpsellsTax + includedUpsellsTax
  const totalAmount = Math.max(serviceChargeAmount + taxAmount + subtotalAmount - promoCodeDiscountAmount + gratuityAmount, 0)
  const giftCardDiscountAmount = redemptionData?.redemptionAmount || 0
  const dueNowAmount = Math.max(totalAmount - giftCardDiscountAmount, 0)

  return {
    baseResAmount: roundCurrency(timeSlotBaseAmount),
    baseChargeAmount: roundCurrency(timeSlotBaseAmount + includedUpsellsBaseAmount),
    serviceChargeAmount: roundCurrency(serviceChargeAmount),
    subtotalAmount: roundCurrency(subtotalAmount),
    promoCodeDiscountAmount: roundCurrency(promoCodeDiscountAmount),
    giftCardDiscountAmount: roundCurrency(giftCardDiscountAmount),
    promoCodeName: promoCode?.code,
    upgradesAmounts,
    dueNowAmount: roundCurrency(dueNowAmount),
    gratuityAmount: roundCurrency(gratuityAmount),
    selectedGratuityAmount: roundCurrency(selectedGratuityAmount),
    taxAmount: roundCurrency(taxAmount),
    totalAmount: roundCurrency(totalAmount),
    upsellAmount: roundCurrency(upsellAmount),
    hasSelectableGratuity:
      includedUpsellsHasSelectableGratuity || selectedUpsellsHasSelectableGratuity || typeof timeSlotSelectedGratuity !== 'undefined',
  }
}

const roundCurrency = (value: number) => Math.round(value * 100) / 100
