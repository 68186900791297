import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import type { ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { Link } from '@sevenrooms/core/ui-kit/typography'
import { useVenue } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { NotificationModal } from '../NotificationModal'

interface ErrorModalProps {
  closeHref?: NonNullable<ModalHeaderProps['closeHref']>
  onClose?: () => void
  errorTitle?: string
  errorMessage?: string
  showTryAgainBtn?: boolean
}

export function ErrorModal({ closeHref, onClose, errorMessage, errorTitle, showTryAgainBtn }: ErrorModalProps) {
  const { formatMessage } = useLocales()
  const venue = useVenue()

  return (
    <NotificationModal
      type="error"
      showIcon
      title={errorTitle || formatMessage(reservationWidgetMessages.resConfirmPageError)}
      primary={
        errorMessage ||
        (venue.phoneNumber
          ? formatMessage(reservationWidgetMessages.resConfirmPageErrorCallVenueSubheader, {
              venue_phone: (
                <Link
                  data-test="venue-phone-number"
                  color="primaryFont"
                  textDecoration="underline"
                  whiteSpace="nowrap"
                  to={`tel:${venue.phoneNumber}`}
                  isExternal
                  target="_self"
                >
                  {FormatService.formatPhoneNumber(venue.phoneNumber, venue.countryCode ?? 'us')}
                </Link>
              ),
            })
          : formatMessage(reservationWidgetMessages.resConfirmPageErrorCallVenueSubheaderNoPhone))
      }
      closeHref={closeHref}
      onClose={onClose}
      actionsPanel={
        showTryAgainBtn ? (
          <Button data-test="sr-try-again-btn" fullWidth variant="primary" onClick={() => onClose?.()}>
            {formatMessage(reservationWidgetMessages.resErrorModalTryAgainButtonLabel)}
          </Button>
        ) : undefined
      }
    />
  )
}
