import styled from 'styled-components'
import { Image } from '@sevenrooms/core/ui-kit/layout'
import type { CSSProperties } from 'react'

export const DEFAULT_HEADER_IMAGE_HEIGHT = 320

export interface HeaderImageProps {
  src: string
  alt: string
  maxWidth?: string
  height?: number
  'data-test'?: string
}

export function HeaderImage({
  src,
  alt,
  maxWidth,
  height = DEFAULT_HEADER_IMAGE_HEIGHT,
  'data-test': dataTest = 'venue-header-image',
}: HeaderImageProps) {
  return (
    <HeaderPictureComponent>
      <source srcSet={src} type="image/webp" />
      <HeaderImageComponent
        src={src}
        alt={alt}
        data-test={dataTest}
        centered
        maxWidth={maxWidth}
        height={height}
        width="100%"
        fetchPriority="high"
        aria-hidden="true"
      />
    </HeaderPictureComponent>
  )
}

const HeaderImageComponent = styled(Image)<{ maxWidth?: CSSProperties['maxWidth']; height: CSSProperties['height'] }>`
  align-self: center;
`
const HeaderPictureComponent = styled.picture`
  display: block;
  width: 100%;
  text-align: center;
  align-self: center;
`
