import type { ReservationWidget } from '@sevenrooms/core/api'

declare const window: ReservationWidget.Window

const dataLayer = window.dataLayer ?? []

const pushEvent = (event: ReservationWidget.GoogleTagEvent) => {
  try {
    dataLayer.push(event)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error pushing event to the data layer. Google tag may be badly configured.')
  }
}

export const loadSearchPage = (urlKey: string, fbPixelIds?: Array<string>, events?: string) => {
  pushEvent({ event: 'loadSearchPage', urlKey })
  trackFBCustomEvents(fbPixelIds, events)
  trackDataLayerCustomEvents(events)
  FBTrack('Search', fbPixelIds)
}

export const loadUpgradesPage = (urlKey: string) => {
  pushEvent({ event: 'loadUpgradesPage', urlKey })
}

export const loadCheckoutPage = (urlKey: string, fbPixelIds?: Array<string>, events?: string) => {
  pushEvent({ event: 'loadCheckoutPage', urlKey })
  trackFBCustomEvents(fbPixelIds, events)
  trackDataLayerCustomEvents(events)
  FBTrack('InitiateCheckout', fbPixelIds)
}

export const finishSearchPage = (urlKey: string) => {
  pushEvent({ event: 'finishSearchPage', urlKey })
}

export const finishUpgradesPage = (urlKey: string) => {
  pushEvent({ event: 'finishUpgradesPage', urlKey })
}

export const finishCheckoutPage = (urlKey: string) => {
  pushEvent({ event: 'finishCheckoutPage', urlKey })
}

export const updateDateSearchParams = (urlKey: string, dateSelected: string) => {
  pushEvent({ event: 'updateDateSearchParams', dateSelected, urlKey })
}

export const updatePartySizeSearchParams = (urlKey: string, partySize: number) => {
  pushEvent({ event: 'updatePartySizeSearchParams', partySize, urlKey })
}

export const updateTimeSearchParams = (urlKey: string, time: string, haloTimeIntervalMinutes: number) => {
  pushEvent({ event: 'updateTimeSearchParams', time, urlKey, haloTimeIntervalMinutes })
}

export const completeUpgradesPage = (urlKey: string) => {
  pushEvent({ urlKey, event: 'completeUpgradesPage' })
}

export const changePreferredLanguage = (urlKey: string, languageCode: string) => {
  pushEvent({ urlKey, languageCode, event: 'changePreferredLanguage' })
}

export const backButtonPressed = (stageWhenBackPressed: string) => {
  pushEvent({ stageWhenBackPressed, event: 'backButtonPressed' })
}

export const navigateToNextPage = (previousStage: string) => {
  pushEvent({ previousStage, event: 'navigateToNextPage' })
}

export const widgetLoadStarted = (urlKey: string, fbPixelIds?: Array<string>, events?: string) => {
  pushEvent({ urlKey, event: 'widgetLoadStarted' })
  trackFBCustomEvents(fbPixelIds, events)
  trackDataLayerCustomEvents(events)
  FBTrack('ViewContent', fbPixelIds)
}

export const widgetLoadCompleted = (urlKey: string) => {
  pushEvent({ urlKey, event: 'widgetLoadCompleted' })
}

export const loginWithSocial = (urlKey: string, loginType: string) => {
  pushEvent({ urlKey, loginType, event: 'loginWithSocial' })
}

export const requestSubmitted = (urlKey: string, day: string, partySize: number, startTime: string, endTime: string) => {
  pushEvent({ urlKey, day, partySize, startTime, endTime, event: 'requestSubmitted' })
}

export const priorityAlertSubmitted = (urlKey: string, day: string, partySize: number, startTime: string, endTime: string) => {
  pushEvent({ urlKey, day, partySize, startTime, endTime, event: 'priorityAlertSubmitted' })
}

export const successfulCheckout = (urlKey: string, token: string, fbPixelIds?: Array<string>, events?: string, data?: object) => {
  pushEvent({ urlKey, token, event: 'successfulCheckout', ...data })
  trackFBCustomEvents(fbPixelIds, events)
  trackDataLayerCustomEvents(events)
  FBTrack('Schedule', fbPixelIds)
  FBTrack('Purchase', fbPixelIds, data)
}

export const failedCheckout = (urlKey: string, errorMessage: string) => {
  pushEvent({ urlKey, errorMessage, event: 'failedCheckout' })
}

export const appliedPromoCode = (urlKey: string, promoCodeId: string) => {
  pushEvent({ urlKey, promoCodeId, event: 'appliedPromoCode' })
}

export const selectTimeslot = (urlKey: string, day: string, timeslot: string, partySize: number) => {
  pushEvent({ urlKey, day, timeslot, partySize, event: 'selectTimeslot' })
}

export const confirmTimeslotWithPaidUpgrades = (urlKey: string, day: string, timeslot: string, partySize: number) => {
  pushEvent({ urlKey, day, timeslot, partySize, event: 'confirmTimeslotWithPaidUpgrades' })
}

export const FBTrack = (eventName: string, fbPixelIds?: Array<string>, data?: object) => {
  if (window.fbq) {
    fbPixelIds?.forEach(pixelId => {
      if (pixelId) {
        window.fbq('trackSingle', pixelId, eventName, data)
      }
    })
  }
}

export const GoogleReserveTrack = () => {
  if (window.post_google_reserve_token) {
    window.post_google_reserve_token()
  }
}

export const trackTealium = (
  venueUrlKey: string,
  venueName: string,
  phoneCountry: string,
  dineDate: string | undefined,
  dineTimeCategory: string | undefined,
  quantity: number
) => {
  if (window.restaurant_data) {
    window.restaurant_data = {
      id: venueUrlKey,
      name: venueName,
      phone_country: phoneCountry,
      dine_date: dineDate,
      dine_time_category: dineTimeCategory,
      quantity,
    }
  }
}

export const trackTealiumChangeDate = (dineDate: string | undefined) => {
  if (window.restaurant_data) {
    window.restaurant_data.dine_date = dineDate
  }
}

export const trackTealiumChangeTime = (dineTimeCategory: string | undefined) => {
  if (window.restaurant_data) {
    window.restaurant_data.dine_time_category = dineTimeCategory
  }
}

export const trackTealiumChangeQuantity = (quantity: number) => {
  if (window.restaurant_data) {
    window.restaurant_data.quantity = quantity
  }
}

export const trackTealiumChangeVenue = (venueUrlKey: string, venueName: string, phoneCountry: string) => {
  if (window.restaurant_data) {
    window.restaurant_data.id = venueUrlKey
    window.restaurant_data.name = venueName
    window.restaurant_data.phone_country = phoneCountry
  }
}

const trackFBCustomEvents = (fbPixelIds?: Array<string>, events?: string) => {
  events?.split(',').forEach(e => e.trim() && FBTrack(e.trim(), fbPixelIds))
}

const trackDataLayerCustomEvents = (events?: string) => {
  events?.split(',').forEach(e => e.trim() && pushEvent({ event: e.trim() }))
}
