import sanitizeHtml from 'sanitize-html'
import type { AvailabilityTime, ReservationHoldErrorResponse } from '@sevenrooms/core/domain'
import type { FormatMessage } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export const shouldShowAvailabilityModal = ({
  publicDescriptionTitle,
  publicLongFormDescription,
  selectedAutomaticUpsells,
  publicPhoto,
}: Pick<AvailabilityTime, 'publicDescriptionTitle' | 'publicLongFormDescription' | 'selectedAutomaticUpsells' | 'publicPhoto'>) =>
  !!(publicDescriptionTitle || '').trim() ||
  !!selectedAutomaticUpsells?.length ||
  !!(publicPhoto || '').trim() ||
  !!(publicLongFormDescription && sanitizeHtml(publicLongFormDescription, { allowedTags: [] }).trim())

export interface GetPartySizeSelectOptionsProps {
  minGuests: number
  maxGuests: number
}
export const getPartySizeSelectOptions = ({ minGuests, maxGuests }: GetPartySizeSelectOptionsProps): SelectOption<number>[] => {
  const selectOptions = []
  for (let guestNumber = minGuests; guestNumber < maxGuests + 1; guestNumber += 1) {
    selectOptions.push({
      id: guestNumber,
      label: `${guestNumber}`,
    })
  }

  return selectOptions
}

export interface GetDurationSelectOptionsProps {
  minimumSearchDuration: number
  maximumSearchDuration: number
  searchDurationInterval: number
  duration: number | null | undefined
}

export const getDurationOptions = ({
  minimumSearchDuration,
  maximumSearchDuration,
  searchDurationInterval,
  duration,
}: GetDurationSelectOptionsProps): SelectOption<number>[] => {
  const size = Math.ceil((maximumSearchDuration - minimumSearchDuration) / searchDurationInterval) + 1
  const options = Array(Math.max(size, 0))
    .fill(0)
    .map((_value, index) => {
      const minutes = minimumSearchDuration + searchDurationInterval * index
      const timeOnly = TimeOnly.from({ minutes })
      return {
        id: minutes,
        label: timeOnly.formatNTimeDuration(),
      }
    })
  const hasSelectedDuration = options.find(option => option.id === duration)
  if (!hasSelectedDuration && duration) {
    const timeOnly = TimeOnly.from({ minutes: duration })
    options.push({
      id: duration,
      label: timeOnly.formatNTimeDuration(),
    })
    options.sort((option1, option2) => option1.id - option2.id)
  }
  return options
}

export const getReservationHoldErrorMessage = (errorResponse: ReservationHoldErrorResponse, formatMessage: FormatMessage): string => {
  const { status: statusCode } = errorResponse

  let errorMessage
  if (statusCode === 470) {
    errorMessage = formatMessage(reservationWidgetMessages.resWidgetReservationHoldTimeNoAvailableMessage)
  } else if (statusCode === 471) {
    errorMessage = formatMessage(reservationWidgetMessages.resWidgetReservationHoldHighDemandMessage)
  }
  if (!errorMessage) {
    errorMessage = formatMessage(reservationWidgetMessages.resWidgetReservationHoldDefaultErrorMessage)
  }
  return errorMessage
}
