import { useCallback } from 'react'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Grid, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { SkeletonBox } from '../../../components/SkeletonBox'
import { useWidgetSettings, type AvailabilityTimeWithUpSellCost } from '../../../hooks'
import { useModals, useReservationFormState } from '../../../store'
import { ExperienceCard, type ExperienceCardProps } from '../ExperienceCard'
import { CreatePriorityAlertSuccessful } from '../PriorityAlert/CreatePriorityAlertSuccessful'
import { CreateRequest } from '../Request/CreateRequest'
import type { PrivateEventsExperience } from '../../../hooks/useAvailability'

const SKELETON_BOX_HEIGHT = '300px'

export interface ExperiencesProps {
  experiences?: PrivateEventsExperience[]
  onTimeSlotClick?: (slot: AvailabilityTimeWithUpSellCost, experience: PrivateEventsExperience) => void
  onMoreDetailsClick?: (experience: PrivateEventsExperience) => void
  desktopVariant?: ExperienceCardProps['variant']
  partySize?: number
  startDate?: string
  isLoading?: boolean
}
export function Experiences({
  desktopVariant = 'full',
  onTimeSlotClick,
  onMoreDetailsClick,
  experiences,
  partySize,
  startDate,
  isLoading,
}: ExperiencesProps) {
  const isMobile = useMaxWidthBreakpoint('s')
  const { enableRequests } = useWidgetSettings()
  const { showModal, hideModal, activeModal } = useModals()
  const {
    formState: { selectedExperience },
    updateFormState,
  } = useReservationFormState()
  const columnsCount = isMobile || desktopVariant === 'full' ? 1 : 2

  const onRequestBtnClick = useCallback(
    (experience: PrivateEventsExperience) => {
      updateFormState({ selectedExperience: experience })
      showModal('createRequest')
    },
    [showModal, updateFormState]
  )

  const onRequestModalClose = useCallback(() => {
    updateFormState({ selectedExperience: undefined })
    hideModal()
  }, [hideModal, updateFormState])

  if (isLoading) {
    return (
      <VStack spacing="m">
        <SkeletonBox height={SKELETON_BOX_HEIGHT} />
        <SkeletonBox height={SKELETON_BOX_HEIGHT} />
      </VStack>
    )
  }

  if (!experiences) {
    return null
  }

  return (
    <>
      <Grid
        data-test="sr-reservation-experiences"
        gap="m"
        gridTemplateColumns={`repeat(${columnsCount}, ${1 / columnsCount}fr)`}
        alignItems="stretch"
      >
        {experiences.map(experience => (
          <ExperienceCard
            key={experience.id}
            experience={experience}
            variant={isMobile ? 'short' : desktopVariant}
            partySize={partySize}
            enableRequests={enableRequests}
            onTimeSlotClick={timeSlot => onTimeSlotClick?.(timeSlot, experience)}
            onMoreDetailsClick={() => onMoreDetailsClick?.(experience)}
            onRequestBtnClick={() => onRequestBtnClick(experience)}
          />
        ))}
      </Grid>
      {activeModal === 'createRequest' && selectedExperience && partySize !== undefined && startDate && (
        <Window active>
          <CreateRequest
            partySize={partySize}
            startDate={startDate}
            validRequestTimes={selectedExperience.requestTimeRange || []}
            onClose={onRequestModalClose}
          />
        </Window>
      )}
      {activeModal === 'createRequestSuccess' && partySize !== undefined && startDate && (
        <Window active>
          <CreatePriorityAlertSuccessful partySize={partySize} startDate={startDate} isPriorityAlert={false} onClose={hideModal} />
        </Window>
      )}
    </>
  )
}
