import { useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'
import { useLocales } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { Button, IconButton } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint, useScrollController } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, HStack, Image, Stack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationTimeslot } from '../../../components/ReservationTimeslot'
import { TimeSlotStack } from '../../../components/TimeSlotStack'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import type { AvailabilityTimeWithUpSellCost } from '../../../hooks'
import type { PrivateEventsExperience } from '../../../hooks/useAvailability'

const imageFullVariantWidth = '40%'
const imageFullVariantHeight = '274px'
const imageShortVariantHeightDefault = '160px'
const imageShortVariantHeightTablet = '200px'
const contentFullVariantWidth = '58%'
const contentMinHeight = '192px'

const timeslotDesktopVariantWidth = '138px'
const timeslotShortVariantWidth = '113px'

export interface ExperienceCardProps {
  variant?: 'full' | 'short'
  experience: PrivateEventsExperience
  enableRequests: boolean
  partySize?: number
  onTimeSlotClick?: (slot: AvailabilityTimeWithUpSellCost) => void
  onMoreDetailsClick?: () => void
  onRequestBtnClick?: () => void
}

export function ExperienceCard({
  experience,
  variant,
  enableRequests,
  onTimeSlotClick,
  onMoreDetailsClick,
  onRequestBtnClick,
  partySize,
}: ExperienceCardProps) {
  const { formatMessage } = useLocales()
  const { heroImage, name: label, description, totalCapacityMax, totalCapacityMin, menu, availabilities, requestTimeRange } = experience
  const descriptionMessageEmpty = !sanitizeHtml(description, { allowedTags: [] }).trim()
  const isSmallMobile = useMaxWidthBreakpoint('xs')
  const isRegularMobile = useMaxWidthBreakpoint('s')

  const imageHeight = useMemo(() => {
    if (variant === 'full') {
      return imageFullVariantHeight
    }
    if (isSmallMobile) {
      return imageShortVariantHeightDefault
    }
    if (isRegularMobile) {
      return imageShortVariantHeightTablet
    }
    return imageShortVariantHeightDefault
  }, [isRegularMobile, isSmallMobile, variant])

  return (
    <Stack
      spacing={variant === 'full' ? 'm' : 'none'}
      p={variant === 'full' ? 'sm' : 'none'}
      flexDirection={variant === 'full' ? 'row' : 'column'}
      borderColor="borders"
      borderRadius="s"
      overflow="hidden"
      data-test={`sr-room-card-${variant}-variant`}
    >
      <Box
        overflow="hidden"
        borderRadius={variant === 'full' ? 's' : 'none'}
        width={variant === 'full' ? imageFullVariantWidth : '100%'}
        minWidth={variant === 'full' ? imageFullVariantWidth : '100%'}
        height={imageHeight}
        minHeight={imageHeight}
      >
        <Image centered height="100%" width="100%" data-test="sr-room-card-image" src={heroImage} alt="room image" />
      </Box>
      <VStack
        width={variant === 'full' ? contentFullVariantWidth : '100%'}
        maxWidth={variant === 'full' ? contentFullVariantWidth : '100%'}
        justifyContent="space-between"
        minHeight={contentMinHeight}
        height="100%"
        p={variant === 'full' ? 'none' : 'sm'}
      >
        <VStack spacing="m">
          <VStack spacing={variant === 'full' ? 'lm' : 'm'}>
            <VStack spacing="sm">
              <Text textStyle="h2" data-test="sr-room-card-label">
                {label}
              </Text>
              {variant === 'full' && !descriptionMessageEmpty && (
                <Text textStyle="body1" textOverflow={3} data-test="sr-room-card-description">
                  <HTMLContent
                    content={description}
                    allowedTags={['div', 'span', 'br', 'u', 'b', 'strong', 'i', 'em', 'a', 'ol', 'ul', 'li']}
                  />
                </Text>
              )}
            </VStack>
            <VStack spacing="xs">
              {totalCapacityMax !== undefined && (
                <HStack spacing="xs" alignItems="center" data-test="sr-room-card-guests-container">
                  <Icon name="GX-groups" />
                  <Text textStyle="body1">
                    {formatMessage(reservationWidgetMessages.resWidgetPrivateEventsGroupBookingsRoomMaxCapacityText, {
                      quantity: totalCapacityMax,
                    })}
                  </Text>
                </HStack>
              )}
              {menu && (
                <HStack spacing="xs" alignItems="center" data-test="sr-room-card-menu-options-container">
                  <Icon name="GX-restaurant" />
                  <Link isExternal to={menu.url} data-test="sr-room-card-menu-link" color="primaryFont" textDecoration="underline">
                    {menu.name}
                  </Link>
                </HStack>
              )}
            </VStack>
          </VStack>
          <Box>
            <Button noPadding variant="tertiary" data-test="sr-room-card-more-details-button" onClick={onMoreDetailsClick}>
              {formatMessage(reservationWidgetMessages.resWidgetPrivateEventsMoreDetailsLabel)}
            </Button>
          </Box>
        </VStack>
        <Timeslots
          variant={variant}
          timeslots={availabilities}
          partySize={partySize}
          totalCapacityMax={totalCapacityMax}
          totalCapacityMin={totalCapacityMin}
          onTimeSlotClick={onTimeSlotClick}
          requestTimeRange={enableRequests ? requestTimeRange : undefined}
          onRequestBtnClick={onRequestBtnClick}
        />
      </VStack>
    </Stack>
  )
}

function Timeslots({
  timeslots,
  onTimeSlotClick,
  requestTimeRange,
  onRequestBtnClick,
  variant = 'full',
  partySize,
  totalCapacityMin,
  totalCapacityMax,
}: {
  variant?: 'full' | 'short'
  timeslots?: AvailabilityTimeWithUpSellCost[]
  partySize?: number
  totalCapacityMax?: number
  totalCapacityMin?: number
  onTimeSlotClick?: (slot: AvailabilityTimeWithUpSellCost) => void
  requestTimeRange?: string[]
  onRequestBtnClick?: () => void
}) {
  const { formatMessage } = useLocales()
  const { spacing } = useTheme()
  const isMobile = useMaxWidthBreakpoint('s')
  const showRequestButton = !!requestTimeRange?.length

  const noAvailabilityMessage = useMemo(() => {
    if (!timeslots) {
      return formatMessage(reservationWidgetMessages.resWidgetPrivateEventsSpecifySearchCriteriaText)
    }
    if (!timeslots?.length && partySize) {
      if (totalCapacityMin !== undefined && partySize < totalCapacityMin) {
        return formatMessage(reservationWidgetMessages.resWidgetPrivateEventsSelectHigherPartySizeText, {
          value: totalCapacityMin,
        })
      }
      if (totalCapacityMax !== undefined && partySize > totalCapacityMax) {
        return formatMessage(reservationWidgetMessages.resWidgetPrivateEventsSelectLowerPartySizeText, {
          value: totalCapacityMax,
        })
      }
    }

    return formatMessage(reservationWidgetMessages.resWidgetPrivateEventsNoAvailabilityAtSelectedDayText)
  }, [timeslots, formatMessage, partySize, totalCapacityMax, totalCapacityMin])

  const timeslotWidth = useMemo(() => {
    const buttonsCount = timeslots?.length || 0 + (showRequestButton ? 1 : 0)
    if (buttonsCount < 4) {
      return '100%'
    }
    return variant === 'full' ? timeslotDesktopVariantWidth : timeslotShortVariantWidth
  }, [showRequestButton, timeslots?.length, variant])
  const scrollStep = (parseInt(timeslotWidth) + parseInt(spacing.sm)) * 2 + 1
  const { ref, scrollLeft, scrollRight, canScrollLeft, canScrollRight } = useScrollController(scrollStep, 'smooth')

  if (!timeslots) {
    return (
      <Text textStyle="body1Bold" textOverflow={2} data-test="sr-room-card-specify-search-criteria-text">
        {noAvailabilityMessage}
      </Text>
    )
  }

  if (timeslots.length) {
    return (
      <VStack>
        <HStack justifyContent="right" alignItems="center" height="32px">
          {!isMobile && timeslots.length > 0 && (canScrollLeft || canScrollRight) && (
            <Flex flex="0 0 auto" ml="s">
              <IconButton
                borderType="none-round"
                size="s"
                icon="VMSWeb-chevron-left"
                data-test="sr-room-card-left-button"
                aria-label="scroll left"
                onClick={scrollLeft}
                disabled={!canScrollLeft}
              />
              <IconButton
                borderType="none-round"
                size="s"
                icon="VMSWeb-chevron-right"
                data-test="sr-room-card-right-button"
                aria-label="scroll right"
                onClick={scrollRight}
                disabled={!canScrollRight}
              />
            </Flex>
          )}
        </HStack>
        <TimeSlotStack
          data-test="sr-room-card-availability-container"
          isMobile={isMobile}
          width="100%"
          spacing="sm"
          justifyContent="flex-start"
          flexWrap="nowrap"
          ref={ref}
        >
          {timeslots.map(timeSlotInfo => (
            <ReservationTimeslot
              variant="primary"
              size="l"
              width={timeslotWidth}
              key={`${timeSlotInfo.time}${timeSlotInfo.accessPersistentId ?? ''}`}
              timeslotDescription={timeSlotInfo.publicTimeSlotDescription}
              time={timeSlotInfo.time}
              onClick={() => {
                onTimeSlotClick?.(timeSlotInfo)
              }}
            />
          ))}
          {showRequestButton && <ContactUsButton onClick={onRequestBtnClick} width={timeslotWidth} />}
        </TimeSlotStack>
      </VStack>
    )
  }

  return (
    <HStack justifyContent="space-between">
      <Text textStyle="body1Bold" textOverflow={2} data-test="sr-room-card-no-availability-text">
        {noAvailabilityMessage}
      </Text>
      {showRequestButton && <ContactUsButton onClick={onRequestBtnClick} width="initial" />}
    </HStack>
  )
}

function ContactUsButton({ width, onClick }: { width: string; onClick?: () => void }) {
  const { formatMessage } = useLocales()
  return (
    <Flex alignItems="center" width={width} minWidth={width === '100%' ? 'initial' : width}>
      <Button variant="tertiary" size="m" fullWidth onClick={onClick} noPadding data-test="sr-room-card-contact-us-button">
        {formatMessage(reservationWidgetMessages.resWidgetPrivateEventsContactUsLabel)}
      </Button>
    </Flex>
  )
}
