import { type ForwardedRef, forwardRef, useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { MonthDayOnly } from '@sevenrooms/core/timepiece'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { ReservationFilterButton } from '../../Reservations/Search/ReservationFilterButton'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { getRelativeFormattedDate } from './getRelativeFormattedDate'

export interface ReservationDayPickerButtonProps {
  label: string
  dataTest: string
  value?: Date
  today?: Date
  isLoading?: boolean
  location: 'center' | 'right'
  onButtonClick: () => void
  isOpen: boolean
}

function ReservationDayPickerButtonComponent(
  { isLoading = false, dataTest, label, value, location, onButtonClick, isOpen }: ReservationDayPickerButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { formatMessage } = useLocales()
  const isSmallMobile = useMaxWidthBreakpoint('s')

  const formattedDate = useMemo(() => {
    if (!value) {
      return formatMessage(reservationWidgetMessages.resWidgetPrivateEventsGroupBookingsDatePlaceholder)
    }
    return isSmallMobile ? MonthDayOnly.fromDate(value).formatSMonthNDay() : getRelativeFormattedDate({ date: value })
  }, [value, formatMessage, isSmallMobile])
  return (
    <ReservationFilterButton
      ref={ref}
      data-test={dataTest}
      isOpen={isOpen}
      isLoading={isLoading}
      label={label}
      location={location}
      onClick={onButtonClick}
      value={formattedDate}
    />
  )
}

export const ReservationDayPickerButton = forwardRef<HTMLButtonElement, ReservationDayPickerButtonProps>(
  ReservationDayPickerButtonComponent
)
