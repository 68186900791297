import { useForm } from '@sevenrooms/core/form'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { useSearchFormSchema } from './Search.zod'

export interface UseSearchFormProps {
  partySize: number
  startDate: string
  startTime: string
  haloTimeIntervalMinutes: number
  duration?: number
}

export function useSearchForm({ partySize, startDate, startTime, haloTimeIntervalMinutes, duration }: UseSearchFormProps) {
  const searchFormSchema = useSearchFormSchema()

  return useForm(searchFormSchema, {
    defaultValues: {
      partySize,
      startTime,
      haloTimeIntervalMinutes,
      duration,
      startDate: DateOnly.from(startDate).toJsDate(),
    },
  })
}
