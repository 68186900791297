import { useLocales, Locale } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { TimeInterval, TimeOnly, DateOnly } from '@sevenrooms/core/timepiece'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { useCreateRequestMutation } from '../../../domain/request'
import { useVenue, useWidgetSettings } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { useReservationFormState } from '../../../store'

interface CreatePriorityAlertSuccessfulProps {
  partySize: number
  startDate: string
  isPriorityAlert: boolean
  onClose: () => void
}

export function CreatePriorityAlertSuccessful({ onClose, isPriorityAlert, partySize, startDate }: CreatePriorityAlertSuccessfulProps) {
  const { formatMessage } = useLocales()
  const navigation = useNavigation()
  const venue = useVenue()
  const venueLocale = Locale.getLocale(venue.locale)
  const {
    formState: { startAndEndTime, timeRange, emailAddress, firstName, lastName, phoneNumber },
  } = useReservationFormState()

  const formattedDate = DateOnly.fromSafe(startDate)?.formatNYearFMonthNDayFWeek()
  const widgetSettings = useWidgetSettings()
  const singleFormatRequestTime = widgetSettings.formatOfRequestingTime === 'single'
  const startTimeOnly =
    singleFormatRequestTime && startAndEndTime ? TimeOnly.fromSafe(startAndEndTime) : TimeOnly.fromSafe(timeRange?.startTime)
  const endTimeOnly =
    singleFormatRequestTime && startAndEndTime ? TimeOnly.fromSafe(startAndEndTime) : TimeOnly.fromSafe(timeRange?.endTime)

  const [, { data }] = useCreateRequestMutation({
    fixedCacheKey: 'reservation-create-request',
  })

  // We know at this point that requestId is defined. Typescript isn't intellegent enough to handle this.
  const { requestId } = data as { success: boolean; requestId: string }

  const title = isPriorityAlert
    ? formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsConfirmationHeader, { venue_name: venue.name })
    : formatMessage(reservationWidgetMessages.resWidgetRequestReceivedLabel)

  const manageAlertLink = navigation.href(routes.explore.reservations.alerts, {
    params: {
      venueKey: venue.urlKey,
    },
    query: {
      date: startDate,
      start_time: timeRange?.startTime,
      end_time: timeRange?.endTime,
      party_size: partySize.toString(),
      request_id: requestId,
    },
  })
  const manageRequestLink = `/direct/request/${requestId}/book?internal_request=1`

  return (
    <Modal data-test="sr-request-or-priority-alert-success-modal" width="100%" ariaLabel={title}>
      <ModalHeader onClose={onClose}>
        <VStack spacing="s">
          <ModalTitle data-test="sr-request-or-priority-alert-success-modal-title" title={title} />
          <Text>{formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsConfirmationMessage)}</Text>
        </VStack>
      </ModalHeader>
      <ModalBody>
        <VStack mb="m" mt="m" spacing="m">
          <HStack spacing="s" alignItems="top">
            <Icon name="GX-calendar-range" size="lg" />
            <Text data-test="sr-request-or-priority-alert-success-modal-date">{formattedDate}</Text>
          </HStack>
          <HStack spacing="s" alignItems="top">
            <Icon name="GX-account-multiple" size="lg" />
            <Text data-test="sr-request-or-priority-alert-success-modal-party-size">
              {`${partySize} ${
                partySize === 1
                  ? formatMessage(reservationWidgetMessages.commonGuestLabel)
                  : formatMessage(reservationWidgetMessages.commonGuestsLabel)
              }`}
            </Text>
          </HStack>
          <HStack spacing="s" alignItems="top">
            <Icon name="GX-clock" size="lg" />
            <Text data-test="sr-request-or-priority-alert-success-modal-timerange">
              {startTimeOnly && TimeInterval.from(startTimeOnly, endTimeOnly).formatSTime(venueLocale, true)}
            </Text>
          </HStack>
          <HStack spacing="s" alignItems="top">
            <Icon name="GX-account-multiple" size="lg" />
            <Text data-test="sr-request-or-priority-alert-success-modal-guest-information">
              {`${firstName} ${lastName}`}
              <br />
              {emailAddress}
              <br />
              {phoneNumber}
            </Text>
          </HStack>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Link
          data-test-id="sr-request-or-priority-alert-success-modal-manage-link"
          {...(isPriorityAlert ? { to: manageAlertLink } : { to: manageRequestLink, target: '_self', isExternal: true })}
        >
          <Text textDecoration="underline" textStyle="body1" color="primaryFont">
            {isPriorityAlert
              ? formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsManageAlertButton)
              : formatMessage(reservationWidgetMessages.resWidgetRequestsManageRequestButton)}
          </Text>
        </Link>
      </ModalFooter>
    </Modal>
  )
}
