import { type DiningWidgetInfoApiResponse, WidgetInfoService } from '@sevenrooms/core/api'

declare const window: Window &
  typeof globalThis & {
    PRELOADED: DiningWidgetInfoApiResponse
  }

const {
  venueInfo,
  clientInfo,
  templateData,
  analyticsSettings,
  salutationOptions,
  widgetSettings,
  privateEventsSettings: _privateEventsSettings,
  ...app
} = WidgetInfoService.infoToClient(window.PRELOADED)

export const preloadedState = {
  app,
  analyticsSettings,
  salutationOptions,
  venueInfo,
  clientInfo,
  widgetSettings,
  templateData,
}

export type PreloadedState = typeof preloadedState
