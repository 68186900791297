import { useMemo } from 'react'
import type { Field } from '@sevenrooms/core/form'
import { Locale, useLocales } from '@sevenrooms/core/locales'
import { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import type { SelectOption, SelectValue, PickerValue } from '@sevenrooms/core/ui-kit/core'
import { useMaxWidthBreakpoint, useThrottledResizeObserver } from '@sevenrooms/core/ui-kit/hooks'
import { Window } from '@sevenrooms/core/ui-kit/layout'
import { ReservationDayPickerForm } from '../../../components/ReservationDayPickerForm'
import { useLanguageStrings, useVenue, useWidgetLanguage, useWidgetSettings } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { useModals } from '../../../store'
import { FilterTime } from '../FilterTime'
import { ReservationFilterButton } from '../ReservationFilterButton'
import { ReservationSearchInputGroup } from '../ReservationSearchInputGroup'
import { ReservationSelectForm } from '../ReservationSelectForm'
import { getDurationOptions, getPartySizeSelectOptions } from '../utils'

export interface ReservationSearchComponentProps<T extends SelectOption<number>, E, C extends boolean> {
  isDatesFetching?: boolean
  isDayBlocked?: (value: Date) => boolean
  partySize: Field<SelectValue<T, E>>
  onPartySizeChange?: (value: SelectValue<T, E>) => void
  startDate: Field<PickerValue<C>>
  onStartDateChange?: (value: PickerValue<C>) => void
  startTime?: string
  haloTimeIntervalMinutes?: number
  onTimeChange?: (value: { startTime: string; haloTimeIntervalMinutes: number }) => void
  selectedDuration?: number | null
  duration?: Field<SelectValue<T, E>>
  onDurationChange?: (value: SelectValue<T, E>) => void
  withEmptySelectOption?: E
  requiredDate?: C
}

const CALENDAR_WIDTH = 364

export function ReservationSearchComponent<T extends SelectOption<number>, E, C extends boolean>({
  isDatesFetching,
  isDayBlocked,
  withEmptySelectOption,
  partySize,
  startDate,
  startTime,
  haloTimeIntervalMinutes,
  onPartySizeChange,
  onStartDateChange,
  onTimeChange,
  selectedDuration,
  duration,
  onDurationChange,
  requiredDate,
}: ReservationSearchComponentProps<T, E, C>) {
  const { formatMessage } = useLocales()
  const { venueToday, locale } = useVenue()
  const { minGuests, maxGuests, minimumSearchDuration, maximumSearchDuration, searchDurationInterval, headerImgUrl } = useWidgetSettings()
  const { isFetching: isFetchingLanguage } = useLanguageStrings()
  const { selectedLanguage } = useWidgetLanguage()
  const { activeModal, showModal, hideModal } = useModals()
  const isSmallDesktop = useMaxWidthBreakpoint('l')
  const isUltraSmallMobile = useMaxWidthBreakpoint('xs')
  const venueLocale = Locale.getLocale(locale)
  const { ref: searchGroupResizeRef, width: reservationSearchInputGroup } = useThrottledResizeObserver(50, 'border-box')

  const partySizeOptions = useMemo(
    () =>
      getPartySizeSelectOptions({
        minGuests,
        maxGuests,
      }) as T[],
    [maxGuests, minGuests]
  )

  const durationOptions = useMemo(
    () =>
      getDurationOptions({
        minimumSearchDuration,
        maximumSearchDuration,
        searchDurationInterval,
        duration: selectedDuration,
      }) as T[],
    [minimumSearchDuration, maximumSearchDuration, searchDurationInterval, selectedDuration]
  )

  const currentDateAtVenue = useMemo(() => DateOnly.fromSafe(venueToday)?.toJsDate() || new Date(), [venueToday])
  const popoverWidth =
    isSmallDesktop && !isUltraSmallMobile ? Math.max((reservationSearchInputGroup || 0) / 2, CALENDAR_WIDTH) : reservationSearchInputGroup
  const showTimeSelect = startTime && haloTimeIntervalMinutes
  const showDurationPicker = duration !== undefined
  const columnsCount = useMemo(() => {
    let count = 2
    if (showTimeSelect) {
      count += 1
    }
    if (showDurationPicker) {
      count += 1
    }
    return count
  }, [showDurationPicker, showTimeSelect])

  return (
    <>
      <ReservationSearchInputGroup ref={searchGroupResizeRef} columnsCount={columnsCount}>
        <ReservationSelectForm
          id="reservation-party-size"
          label={formatMessage(reservationWidgetMessages.resWidgetGuestsLabel)}
          dataTest="sr-reservation-party-size"
          location="left"
          isLoading={isFetchingLanguage}
          selectOptions={partySizeOptions}
          field={partySize}
          withEmpty={withEmptySelectOption}
          onChange={onPartySizeChange}
        />
        {showTimeSelect && (
          <ReservationFilterButton
            isOpen={activeModal === 'filterTime'}
            isLoading={isFetchingLanguage}
            label={formatMessage(reservationWidgetMessages.resWidgetTimePickerLabel)}
            location="center"
            onClick={() => showModal('filterTime')}
            value={
              TimeOnly.fromSafe(startTime)?.formatSTime(venueLocale) || formatMessage(reservationWidgetMessages.resWidgetAllTimesLabel)
            }
          />
        )}
        <ReservationDayPickerForm
          label={formatMessage(reservationWidgetMessages.resWidgetDatePickerLabel)}
          isLoading={isFetchingLanguage || isDatesFetching}
          isDayBlocked={isDayBlocked}
          dataTest="sr-reservation-date"
          value={startDate}
          required={requiredDate}
          today={currentDateAtVenue}
          locale={selectedLanguage}
          popoverWidth={popoverWidth}
          onChange={onStartDateChange}
          scrollTopOnMobile={!!headerImgUrl}
          location={showDurationPicker ? 'center' : 'right'}
        />
        {showDurationPicker && (
          <ReservationSelectForm
            id="reservation-duration"
            label={formatMessage(reservationWidgetMessages.resWidgetDurationPickerLabel)}
            dataTest="sr-reservation-duration"
            location="right"
            isLoading={isFetchingLanguage}
            selectOptions={durationOptions}
            field={duration}
            onChange={onDurationChange}
            withEmpty={withEmptySelectOption}
          />
        )}
      </ReservationSearchInputGroup>
      {activeModal === 'filterTime' && showTimeSelect && (
        <Window active>
          <FilterTime
            startTime={startTime}
            onChange={onTimeChange}
            onClose={hideModal}
            haloTimeIntervalMinutes={haloTimeIntervalMinutes}
            venueLocale={venueLocale}
          />
        </Window>
      )}
    </>
  )
}
