import { type ForwardedRef, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { selectChevronStyling } from '@sevenrooms/core/ui-kit/core'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, VStack } from '@sevenrooms/core/ui-kit/layout'
import { ReservationInputLoader } from '../ReservationSelectForm'

const DESKTOP_HEIGHT = '84px'
const MOBILE_HEIGHT = '58px'

interface ReservationFilterButtonProps {
  isLoading: boolean
  isOpen: boolean
  label: string
  location: 'left' | 'center' | 'right'
  onClick: () => void
  onFocus?: () => void
  value: string
  'data-test'?: string
}

export function ReservationFilterButtonComponent(
  { label, value, isLoading, onClick, location, isOpen, onFocus, 'data-test': testId }: ReservationFilterButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  if (isLoading) {
    return <ReservationInputLoader location={location} />
  }

  return (
    <StyledButton
      aria-label={`${label} ${value}`}
      data-test={testId}
      isLoading={isLoading}
      disabled={isLoading}
      onClick={isLoading ? undefined : onClick}
      onFocus={onFocus}
      location={location}
      type="button"
      ref={ref}
    >
      <Flex width="100%" p="none" justifyContent="space-between" position="relative">
        <VStack>
          <StyledLabel>{label}</StyledLabel>
          <StyledValue>{value}</StyledValue>
        </VStack>
        <StyledToggle open={isOpen}>
          <Icon name="VMSWeb-chevron-down" color="primaryIcons" />
        </StyledToggle>
      </Flex>
    </StyledButton>
  )
}

export const ReservationFilterButton = forwardRef(ReservationFilterButtonComponent)

const StyledLabel = styled.span`
  color: ${props => props.theme.colors.primaryIcons};
  font: ${props => props.theme.textStyles.body1};
  @media (max-width: ${props => props.theme.breakpoints.s}px) {
    font: ${props => props.theme.textStyles.body2};
    margin-top: 1px;
  }
  line-height: 100% !important;
`
const StyledValue = styled.span`
  color: ${props => props.theme.colors.primaryFont};
  font: ${props => props.theme.textStyles.h2};
  margin-top: ${props => props.theme.spacing.s};
  @media (max-width: ${props => props.theme.breakpoints.s}px) {
    margin-top: 3px;
    font: ${props => props.theme.textStyles.body1Bold};
  }
  line-height: 100% !important;
`

const StyledButton = styled.button<{ isLoading: boolean; location: 'left' | 'center' | 'right' }>`
  background-color: ${props => props.theme.colors.primaryBackground};
  border-radius: ${props => {
    if (props.location === 'left') {
      return `${props.theme.borderRadius.s} 0 0 ${props.theme.borderRadius.s}`
    } else if (props.location === 'right') {
      return `0 ${props.theme.borderRadius.s} ${props.theme.borderRadius.s} 0`
    }
    return 0
  }};
  border-left-width: ${props => (props.location === 'left' ? props.theme.borderWidth.s : props.theme.borderWidth.none)};
  border: solid ${props => props.theme.colors.borders} ${props => props.theme.borderWidth.s};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: ${DESKTOP_HEIGHT};
  outline: 0;
  padding: ${props => props.theme.spacing.lm} 0 0 ${props => props.theme.spacing.m};

  ${props =>
    ['center', 'right'].includes(props.location) &&
    css`
      margin-left: -1px;
    `}

  ${props =>
    props.isLoading &&
    css`
      & > div:first-child {
        margin-bottom: 6px;
      }
    `}

  ${props =>
    !props.isLoading &&
    css`
      &:focus,
      &:hover {
        border-color: ${props => props.theme.colors.borderHover};
        z-index: ${props => props.theme.zIndex.default};
      }
    `}


  @media (max-width: ${props => props.theme.breakpoints.s}px) {
    padding-top: 14px;
    height: ${MOBILE_HEIGHT};
    white-space: nowrap;
  }
`

const StyledToggle = styled(Box)<{ open: boolean }>`
  position: absolute;
  right: 0;
  padding: ${props => `${props.theme.spacing.sm} ${props.theme.spacing.m} ${props.theme.spacing.sm} ${props.theme.spacing.s}`};
  margin-top: -3px;

  @media (max-width: ${props => props.theme.breakpoints.s}px) {
    margin-top: -6px;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}px) {
    padding-right: ${props => props.theme.spacing.xs};
  }

  ${selectChevronStyling}
`
