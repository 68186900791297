import React, { type ChangeEvent } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface RadioTileProps<T extends number | string>
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'id' | 'name' | 'onChange' | 'value' | 'disabled'> {
  id: string
  label: string
  name: string
  value: T
  onChange: (value: T) => void
  disabled?: boolean
  sticky?: boolean | undefined
  hideIcon?: boolean
  showBorder?: boolean
}

export function RadioTile<T extends number | string>({
  id,
  name,
  value,
  label,
  checked,
  onChange,
  disabled,
  sticky,
  hideIcon,
  showBorder = true,
  ...props
}: RadioTileProps<T>) {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange((typeof value === 'number' ? parseInt(event.target.value) : event.target.value) as T)
  }
  return (
    <Flex mb="xs" width="100%" top={sticky ? 'none' : undefined} position={sticky ? 'sticky' : undefined}>
      <RadioTileInput
        disabled={disabled}
        checked={checked}
        id={id}
        name={name}
        onChange={handleOnChange}
        type="radio"
        value={value}
        {...props}
      />
      <RadioTileLabel showBorder={showBorder} disabled={disabled} checked={checked} hideIcon={hideIcon} htmlFor={id}>
        {!hideIcon && <Icon name={checked ? 'GX-radio-button-checked' : 'GX-radio-button-unchecked'} />}
        <Text color="inherit" fontSize="xl" fontWeight={500}>
          {label}
        </Text>
        {!hideIcon && <span />}
      </RadioTileLabel>
    </Flex>
  )
}

const RadioTileInput = styled.input`
  height: 0;
  width: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`

const RadioTileLabel = styled.label<Pick<RadioTileProps<string>, 'hideIcon' | 'checked' | 'disabled' | 'showBorder'>>`
  background-color: ${props => (props.disabled ? props.theme.colors.inputHoverBackground : props.theme.colors.primaryBackground)};
  box-sizing: border-box;
  cursor: ${props => (props.disabled ? 'inactive' : 'pointer')};
  height: ${props => props.theme.fieldHeight.m};
  padding: ${props => props.theme.spacing.s} ${props => props.theme.spacing.m};
  display: flex;
  align-items: center;
  justify-content: ${props => (props.hideIcon ? 'center' : 'space-between')};
  user-select: none;
  border-radius: ${props => props.theme.borderRadius.s};
  margin-top: 0;
  width: 100%;
  white-space: nowrap;

  ${props =>
    props.showBorder &&
    css`
      border: ${props => `${props.theme.borderWidth.m} solid ${props.theme.colors.borders}`};
    `}

  ${props =>
    props.checked &&
    css`
      background-color: ${props => props.theme.colors.secondaryBackground};
      border: ${props => `${props.theme.borderWidth.m} solid ${props.theme.colors.darkFont}`};
    `}

  ${RadioTileInput}:focus-visible ~ & {
    outline: 3px solid ${props => props.theme.colors.dividerLines};
    outline-offset: 2px;
  }

  ${RadioTileInput}:hover ~ & {
    background: ${props => props.theme.colors.inputHoverBackground};
  }

  ${RadioTileInput}[disabled] ~ & {
    color: ${props => props.theme.colors.inactive};
    outline: 0;
    border: 0;
  }
`
