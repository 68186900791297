import { useCallback } from 'react'
import FocusLock from 'react-focus-lock'
import { TouchScrollable } from 'react-scrolllock'
import type { LanguageCode } from '@sevenrooms/core/api'
import type { Field } from '@sevenrooms/core/form'
import type { PickerValue } from '@sevenrooms/core/ui-kit/core'
import { FormCalendar } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Flex } from '@sevenrooms/core/ui-kit/layout'

interface ReservationCalendarFormProps<T extends boolean> {
  dataTest: string
  value: Field<PickerValue<T>>
  today?: Date
  isDayBlocked?: (day: Date) => boolean
  onChange?: (date: PickerValue<T>) => void
  locale: LanguageCode
  popoverWidth?: number
  required?: T
  setIsOpen: (value: boolean) => void
}

export function ReservationCalendarForm<T extends boolean>({
  dataTest,
  value,
  today,
  isDayBlocked,
  onChange,
  locale,
  popoverWidth,
  required,
  setIsOpen,
}: ReservationCalendarFormProps<T>) {
  const isSmallDesktop = useMaxWidthBreakpoint('l')
  const isSmallMobile = useMaxWidthBreakpoint('s')

  const onDayPickerChange = useCallback(
    (date: PickerValue<T>) => {
      onChange?.(date)
      setIsOpen(false)
    },
    [setIsOpen, onChange]
  )
  return (
    <TouchScrollable>
      <Flex
        justifyContent="center"
        backgroundColor="primaryBackground"
        boxShadow="primary"
        borderColor="borders"
        borderRadius="s"
        width={popoverWidth ? `${popoverWidth}px` : undefined}
        overflowY="scroll"
        overflowX="hidden"
        // workaround to make day picker scrollable, didn't find a better solution with current Popover implementation
        maxHeight={isSmallMobile ? 'calc(100vh - 130px)' : 'calc(100vh - 170px)'}
      >
        <FocusLock returnFocus>
          <FormCalendar
            today={today}
            value={value}
            dataTest={`${dataTest}-day-picker`}
            onChange={onDayPickerChange}
            numberOfMonths={isSmallDesktop ? 1 : 2}
            locale={locale}
            disabled={isDayBlocked}
            initialFocus
            required={required}
          />
        </FocusLock>
      </Flex>
    </TouchScrollable>
  )
}
