import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo, useEffect } from 'react'
import type { LanguageCode } from '@sevenrooms/core/api'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { WidgetContainer } from '../../components/WidgetContainer'
import {
  useAppLoading,
  useVenue,
  useWidgetLanguage,
  useWidgetSettings,
  useGetSevenroomsLogin,
  useModifyReservationRoute,
  useReservationsRoute,
} from '../../hooks'
import { useAppDispatch, updateFormState, useGetDiningVenuesInfoQuery } from '../../store'
import { Search } from './Search'
import { SearchContainer } from './SearchContainer'
import { SearchLoader } from './SearchLoader'

interface SearchAndAvailabilityProps {
  shouldDisplayLoader: boolean
}
export function SearchAndAvailability({ shouldDisplayLoader }: SearchAndAvailabilityProps) {
  const { availableLanguageOptions, selectedLanguage, setSelectedLanguage } = useWidgetLanguage()
  const isLoading = useAppLoading()
  const dispatch = useAppDispatch()
  const { largeLogoUrl, urlKey, name, multiVenueReservationEnabled, newResWidgetPrivateEventsEnabled, countOfPrivateEvents } = useVenue()
  const { headerImgUrl, headerImgMobileUrl } = useWidgetSettings()
  const { isModifyRoute } = useModifyReservationRoute()
  const isMobile = useMaxWidthBreakpoint('s')
  const finalHeaderImgUrl = useMemo(() => {
    if (isModifyRoute) {
      return undefined
    }
    if (isMobile && headerImgMobileUrl) {
      return headerImgMobileUrl
    }
    return headerImgUrl
  }, [isModifyRoute, isMobile, headerImgUrl, headerImgMobileUrl])
  const languagePicker = useMemo(() => {
    if (availableLanguageOptions.length <= 1) {
      return undefined
    }
    const handleSetSelectedLanguage = (language: LanguageCode) => {
      setSelectedLanguage(language)
      dispatch(updateFormState({ preferredLanguage: language }))
    }
    return {
      value: selectedLanguage,
      onChange: handleSetSelectedLanguage,
      options: availableLanguageOptions,
      disabled: isLoading,
    }
  }, [availableLanguageOptions, dispatch, isLoading, selectedLanguage, setSelectedLanguage])

  const { venues, clearQueryParams, hideTabs } = useReservationsRoute()
  const venuesFromParam = venues?.split(',') || []
  const otherVenues = venuesFromParam.filter(v => v !== urlKey)
  const hasCurrentVenueInParam = venuesFromParam.includes(urlKey)
  const shouldFetchOtherVenuesInfo = multiVenueReservationEnabled && otherVenues && otherVenues.length > 0

  // no group nor cross-promotion widget -> venuesInfo is undefined
  // group widget -> venuesInfo.venues > 0 and current venue not in param
  // cross-promotion widget -> venuesInfo.venues > 0 and current venue not in param
  const { isFetching: isFetchingVenuesInfo, data: venuesInfo } = useGetDiningVenuesInfoQuery(
    shouldFetchOtherVenuesInfo ? { venueUrlKey: urlKey, venues: otherVenues.join(',') } : skipToken
  )

  const isGroupWidget = !!(shouldFetchOtherVenuesInfo && !hasCurrentVenueInParam && venuesInfo && venuesInfo.venues.length > 0)

  // For now tabs are not available for modify route and group widget
  const showTabs = !hideTabs && !isModifyRoute && !isGroupWidget
  const showPrivateEvents = newResWidgetPrivateEventsEnabled && !!countOfPrivateEvents

  const sevenRoomsLogin = useGetSevenroomsLogin()

  useEffect(() => {
    if (!multiVenueReservationEnabled && venues) {
      clearQueryParams('venues')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (shouldFetchOtherVenuesInfo && isFetchingVenuesInfo) {
    return null
  }

  return (
    <WidgetContainer
      largeLogoUrl={largeLogoUrl}
      urlKey={urlKey}
      venueName={name}
      headerImageUrl={isModifyRoute ? undefined : finalHeaderImgUrl}
      breadcrumbsPortalId="search-breadcrumbs"
      enableGrowingTopNav={!showTabs && !isModifyRoute}
      enableGrowingBreadcrumbs={isModifyRoute}
      showGutters
      languagePicker={languagePicker}
      sevenRoomsLogin={sevenRoomsLogin}
    >
      <SearchContainer>
        {shouldDisplayLoader ? (
          <SearchLoader isModifyRoute={isModifyRoute} />
        ) : (
          <Search isGroupWidget={isGroupWidget} showTabs={showTabs} showPrivateEvents={showPrivateEvents} venuesInfo={venuesInfo} />
        )}
      </SearchContainer>
    </WidgetContainer>
  )
}

export * from './useSearchForm'
