import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import type { ReservationWidget as ReservationWidgetModel } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Flex, Tab, TabList, Tabs, VStack, TabPanel } from '@sevenrooms/core/ui-kit/layout'
import { useReservationsRoute } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { useReservationFormState } from '../../../store'
import { PrivateEventsTab } from '../PrivateEventsTab'
import { ReservationsTab } from '../ReservationsTab'

interface ReservationsTabs {
  isGroupWidget: boolean
  venuesInfo?: ReservationWidgetModel.VenueInfo
}

export function ReservationsTabs({ isGroupWidget, venuesInfo }: ReservationsTabs) {
  const { formatMessage } = useLocales()
  const {
    formState: { searchTab },
    updateFormState,
  } = useReservationFormState()
  const { updateQuery } = useReservationsRoute()
  const TABS_DISPLAY_NAMES: Record<ReservationWidgetModel.SearchTab, string> = useMemo(
    () => ({
      reservations: formatMessage(reservationWidgetMessages.resWidgetPrivateEventsReservationsTabLabel),
      group_bookings: formatMessage(reservationWidgetMessages.resWidgetPrivateEventsGroupBookingsTabLabel),
    }),
    [formatMessage]
  )
  const tabs = useMemo(
    () => Object.keys(TABS_DISPLAY_NAMES).map((id: string) => ({ id, text: TABS_DISPLAY_NAMES[id as ReservationWidgetModel.SearchTab] })),
    [TABS_DISPLAY_NAMES]
  )
  const selectedTabIndex = useMemo(() => tabs.findIndex(({ id }) => id === searchTab), [searchTab, tabs])
  const onTabChange = useCallback(
    (index: number) => {
      const searchTab = tabs[index]?.id as ReservationWidgetModel.SearchTab
      updateFormState({ searchTab })
      updateQuery({ searchTab })
    },
    [tabs, updateFormState, updateQuery]
  )
  return (
    <StyledTabs>
      <Tabs onSelect={onTabChange} selectedIndex={selectedTabIndex}>
        <StickyContainer>
          <TabList>
            {tabs.map(tab => (
              <Tab key={tab.id} data-test={`sr-${tab.id.toLowerCase()}-tab`}>
                {tab.text}
              </Tab>
            ))}
          </TabList>
          <StyledSpacer width="100%" backgroundColor="primaryBackground" />
        </StickyContainer>
        <TabPanel data-test="sr-reservations-tab-content">
          <ReservationsTab isGroupWidget={isGroupWidget} venuesInfo={venuesInfo} showPrivateEvents />
        </TabPanel>
        <TabPanel data-test="sr-private-events-tab-content">
          <PrivateEventsTab showTabs />
        </TabPanel>
      </Tabs>
    </StyledTabs>
  )
}

const StyledSpacer = styled(Flex)`
  height: ${props => props.theme.spacing.sm};
`

const StickyContainer = styled(VStack)`
  position: sticky;
  z-index: ${props => props.theme.zIndex.navbar + 1};
  top: ${props => `calc(${props.theme.topNav.height.desktop} + 1px)`};

  @media (max-width: ${props => props.theme.breakpoints.m}px) {
    top: ${props => `calc(${props.theme.topNav.height.mobile} + 1px)`};
  }

  background-color: ${props => props.theme.colors.primaryBackground};
  width: 100%;
`

const StyledTabs = styled(VStack)`
  height: 100%;

  // Specify height for Tabs
  & > * {
    height: 100%;
  }

  ${TabList} {
    width: 100%;
  }

  ${Tab} {
    width: 50%;
    text-align: center;
    padding: ${props => `${props.theme.spacing.sm} ${props.theme.spacing.lm}`};
    font: ${props => props.theme.textStyles.h3};
    font-weight: 600;

    &:not(.selected) {
      font-weight: 400;
    }
  }

  ${Tab} + ${Tab} {
    margin-left: 0;
  }
`
