import { useCallback, useEffect, useRef, useState } from 'react'
import mergeRefs from 'react-merge-refs'
import styled from 'styled-components'
import type { LanguageCode } from '@sevenrooms/core/api'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useTheme } from '@sevenrooms/core/ui-kit'
import type { PickerValue } from '@sevenrooms/core/ui-kit/core'
import { useMaxWidthBreakpoint, useWindowSize } from '@sevenrooms/core/ui-kit/hooks'
import { Popover, type PopoverTargetRenderProps } from '@sevenrooms/core/ui-kit/layout'
import { DEFAULT_HEADER_IMAGE_HEIGHT } from '../HeaderImage'
import { ReservationCalendarForm } from './ReservationCalendarForm'
import { ReservationDayPickerButton } from './ReservationDayPickerButton'

interface ReservationDayPickerFormProps<T extends boolean> {
  label: string
  dataTest: string
  value: Field<PickerValue<T>>
  today?: Date
  isLoading?: boolean
  isDayBlocked?: (day: Date) => boolean
  onChange?: (date: PickerValue<T>) => void
  locale: LanguageCode
  scrollLock?: boolean
  scrollTopOnMobile?: boolean
  popoverWidth?: number
  location: 'center' | 'right'
  required?: T
}

export function ReservationDayPickerForm<T extends boolean>({
  isLoading = false,
  dataTest,
  label,
  value,
  today,
  isDayBlocked,
  onChange,
  scrollLock,
  scrollTopOnMobile,
  locale,
  popoverWidth,
  required,
  location,
}: ReservationDayPickerFormProps<T>) {
  const theme = useTheme()
  const isSmallMobile = useMaxWidthBreakpoint('s')
  const dateValue = useWatch(value)
  const calendarAnchorRef = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState(false)

  const closePopper = useCallback(() => {
    setIsOpen(false)
  }, [])

  const size = useWindowSize()

  useEffect(() => {
    // close popover on resize
    setIsOpen(false)
  }, [setIsOpen, size])

  const onButtonClick = useCallback(() => {
    if (isSmallMobile && scrollTopOnMobile && !isOpen) {
      // for better user experience scroll top to fit day picker on screens with small height
      window.scroll({ top: DEFAULT_HEADER_IMAGE_HEIGHT, behavior: 'smooth' })
    }
    setIsOpen(!isOpen)
  }, [isOpen, isSmallMobile, scrollTopOnMobile])

  const renderTarget = useCallback(
    (props: PopoverTargetRenderProps<HTMLDivElement>) => (
      <>
        <ReservationDayPickerButton
          label={label}
          dataTest={dataTest}
          value={dateValue}
          today={today}
          isLoading={isLoading}
          location={location}
          onButtonClick={onButtonClick}
          isOpen={isOpen}
        />
        <StyledAnchor ref={mergeRefs([calendarAnchorRef, props.ref])} />
      </>
    ),
    [label, dataTest, dateValue, today, isLoading, location, onButtonClick, isOpen]
  )

  const renderContent = () => (
    <ReservationCalendarForm
      dataTest={dataTest}
      locale={locale}
      setIsOpen={setIsOpen}
      value={value}
      required={required}
      today={today}
      isDayBlocked={isDayBlocked}
      onChange={onChange}
      popoverWidth={popoverWidth}
    />
  )

  return (
    <Popover
      targetOffset={parseInt(theme.spacing.xs)}
      bodyOffset={0}
      alignment="bottomRight"
      show={isOpen}
      onClose={closePopper}
      content={renderContent}
      scrollLock={scrollLock}
      useSmartAlignment={false}
    >
      {renderTarget}
    </Popover>
  )
}

const StyledAnchor = styled.div`
  //  a workaround to display anchor as last element in grid layout
  order: 100;
  grid-column: -1;
`
