import { WidgetLanguageService, RTKUtils } from '@sevenrooms/core/api'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

export const widgetLanguageApi = baseReservationWidgetApi.injectEndpoints({
  endpoints: builder => ({
    getLanguageStrings: builder.query({
      queryFn: RTKUtils.rtkQuery(WidgetLanguageService.getLanguageStrings),
    }),
  }),
})

export const { useGetLanguageStringsQuery } = widgetLanguageApi
