import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { AgreementTerm, type OrderAgreementTermSingleProps } from './AgreementTerm'

export interface OrderAgreementTermListProps {
  agreements: OrderAgreementTermSingleProps[]
  disabled?: boolean
}

export function AgreementTerms({ agreements, disabled }: OrderAgreementTermListProps) {
  if (agreements.length === 0) {
    return null
  }

  return (
    <VStack spacing="m">
      {agreements.map(agreement => (
        <AgreementTerm key={agreement.term} disabled={disabled} agreement={agreement} />
      ))}
    </VStack>
  )
}
