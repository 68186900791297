import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { ReservationBreadcrumbLoader } from '../../../components/ReservationBreadcrumb'
import { SkeletonBox } from '../../../components/SkeletonBox'
import { WidgetPortal } from '../../../components/WidgetPortal'
import { CheckoutContainer } from '../CheckoutContainer'

export function CheckoutLoader() {
  return (
    <CheckoutContainer reservationDetails={<SkeletonBox height="475px" borderWidth="none" borderRadius="s" width="100%" />}>
      <VStack spacing="m" height="75vh">
        <SkeletonBox height="90%" borderWidth="none" borderRadius="s" width="100%" />
        <SkeletonBox height="56px" borderWidth="none" borderRadius="s" width="100%" />
      </VStack>
      <WidgetPortal portalId="checkout-breadcrumbs">
        <ReservationBreadcrumbLoader />
      </WidgetPortal>
    </CheckoutContainer>
  )
}
