import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect, useMemo, useState } from 'react'
import type { ReservationWidget } from '@sevenrooms/core/api'
import type { LanguageCode } from '@sevenrooms/core/locales'
import usEnglishTranslationStrings from '../lang/compiled/en-US.json'
import { useGetLanguageStringsQuery } from '../store'
import { useReservationsRoute } from './useReservationsRoute'
import { useWidgetLanguage } from './useWidgetLanguage'

async function loadTranslationFile(languageCode: LanguageCode) {
  switch (languageCode) {
    case 'ca':
      return import('../lang/compiled/ca-ES.json')
    case 'da':
      return import('../lang/compiled/da-DK.json')
    case 'de':
      return import('../lang/compiled/de-DE.json')
    case 'es':
      return import('../lang/compiled/es-ES.json')
    case 'et':
      return import('../lang/compiled/et-EE.json')
    case 'fr':
      return import('../lang/compiled/fr-FR.json')
    case 'hu':
      return import('../lang/compiled/hu-HU.json')
    case 'is':
      return import('../lang/compiled/is-IS.json')
    case 'it':
      return import('../lang/compiled/it-IT.json')
    case 'ja':
      return import('../lang/compiled/ja-JP.json')
    case 'nb':
      return import('../lang/compiled/no-NO.json')
    case 'nl':
      return import('../lang/compiled/nl-NL.json')
    case 'pt':
      return import('../lang/compiled/pt-BR.json')
    case 'sv':
      return import('../lang/compiled/sv-SE.json')
    case 'th':
      return import('../lang/compiled/th-TH.json')
    case 'tr':
      return import('../lang/compiled/tr-TR.json')
    case 'zh_CN':
      return import('../lang/compiled/zh-CN.json')
    case 'zh_HK':
      return import('../lang/compiled/zh-HK.json')
    case 'en':
    default:
      return import('../lang/compiled/en-US.json')
  }
}

type CacheType = Exclude<Awaited<ReturnType<typeof loadTranslationFile>>, undefined>['default']

class LanguageStrings {
  private cache: { [key: string]: CacheType } = {}

  constructor() {
    this.cache = { en: usEnglishTranslationStrings }
  }

  public async getTranslations(language: LanguageCode) {
    if (this.cache[language]) {
      return this.cache[language]
    }

    const loadedLanguageStrings = await loadTranslationFile(language)

    if (!loadedLanguageStrings) {
      return undefined
    }

    this.cache[language] = loadedLanguageStrings.default
    return this.cache[language]
  }

  public getCacheValue(language: LanguageCode) {
    return this.cache[language]
  }
}

const languageStrings = new LanguageStrings()

export function useLanguageStrings() {
  const { venueKey } = useReservationsRoute()
  const { selectedLanguage, previouslySelectedLanguage } = useWidgetLanguage()
  const { isError, isFetching, data } = useGetLanguageStringsQuery(
    !venueKey
      ? skipToken
      : {
          venueUrlKey: venueKey,
          widgetType: 'res_widget',
          languageCode: selectedLanguage,
        }
  )
  const [loadedTranslationStrings, setLoadedTranslationStrings] = useState<CacheType>()

  useEffect(() => {
    const cachedValue = languageStrings.getCacheValue(selectedLanguage)
    if (cachedValue) {
      setLoadedTranslationStrings(cachedValue)
      return
    }
    async function loadTranslationStrings(language: LanguageCode) {
      const loadedLanguageStrings = await languageStrings.getTranslations(language)
      setLoadedTranslationStrings(loadedLanguageStrings)
    }

    loadTranslationStrings(selectedLanguage)
  }, [selectedLanguage])

  const loadedLanguage = useMemo(
    () => (selectedLanguage && !isFetching ? selectedLanguage : previouslySelectedLanguage),
    [isFetching, previouslySelectedLanguage, selectedLanguage]
  )

  const messages = useMemo(
    () =>
      ({
        ...loadedTranslationStrings,
        ...data?.languageStrings,
      } as ReservationWidget.LanguageStrings),
    [data, loadedTranslationStrings]
  )

  return {
    selectedLanguage,
    loadedLanguage,
    messages,
    isFetching,
    isError,
  }
}
