import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { Link, Text, type TextProps } from '@sevenrooms/core/ui-kit/typography'
import { useVenue } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

interface CallVenueMessageProps extends TextProps {}

export function CallVenueMessage({ ...props }: CallVenueMessageProps) {
  const { formatMessage } = useLocales()
  const venue = useVenue()

  return (
    <Text {...props}>
      {venue.phoneNumber
        ? formatMessage(reservationWidgetMessages.resCancelPageCallVenueSubheader, {
            venue_phone: (
              <Link
                data-test="venue-phone-number"
                color="primaryFont"
                textDecoration="underline"
                to={`tel:${venue.phoneNumber}`}
                isExternal
                target="_self"
                {...props}
              >
                {FormatService.formatPhoneNumber(venue.phoneNumber, venue.countryCode ?? 'us')}
              </Link>
            ),
          })
        : formatMessage(reservationWidgetMessages.resCancelPageCallVenueSubheaderNoPhone)}
    </Text>
  )
}
