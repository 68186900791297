import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect } from 'react'
import type { CountryCode } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Route, Switch, Redirect } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/routes'
import {
  useReservationNavigation,
  useModifyReservationRoute,
  useManageReservationRoute,
  useReservationsRoute,
  useVenue,
  useWidgetLanguage,
} from '../hooks'
import { reservationWidgetMessages } from '../reservationWidgetMessages'
import { useGetManageReservationDetailsQuery, useGetVenueTagsQuery, useModals, useReservationFormState } from '../store'
import { CheckoutPage } from './Checkout'
import { SearchAndAvailability } from './Search'
import { UpgradesPage } from './Upgrades'

const modifyPath = routes.explore.reservations.modify

export function Modify() {
  const { showErrorModal } = useModals()
  const { formatMessage } = useLocales()
  const { id: venueId } = useVenue()
  const { selectedLanguage } = useWidgetLanguage()
  const { linkToModifyReservation } = useManageReservationRoute()
  const { hasPaymentError } = useReservationsRoute()
  const { lang, token } = useModifyReservationRoute()
  const { setActiveFlow, currentStep } = useReservationNavigation()
  const { updateFormState, formState } = useReservationFormState()
  const { data: currentReservationDetail, isLoading, isError } = useGetManageReservationDetailsQuery(token ? { lang, token } : skipToken)

  // calling get query tags earlier to preload them
  useGetVenueTagsQuery({ venueId, lang: selectedLanguage })

  useEffect(() => {
    setActiveFlow('modify')
  }, [setActiveFlow])

  useEffect(() => {
    const flags = currentReservationDetail?.actualFlags
    if (flags && (!flags.isEditable || flags.hasPassed)) {
      window.location.replace(linkToModifyReservation)
    }
  }, [currentReservationDetail, linkToModifyReservation])

  useEffect(() => {
    if (!isLoading && currentReservationDetail) {
      const {
        maxGuests,
        arrivalTime,
        date,
        birthdayMonth,
        birthdayDay,
        firstName,
        lastName,
        duration,
        email,
        phoneNumber,
        phoneNumberLocale,
        postalCode,
        salutation,
        champagnePreferenceTags,
        clientDietaryPreferenceTags,
        liquorPreferenceTags,
        partyDietaryPreferenceTags,
        specialOccasionTags,
        agreedToGdprDietaryOptin,
        customQuestionResponseNote,
        isDurationPicked,
      } = currentReservationDetail.actual
      const { venueSmsMarketingEnabled, preferredMessagingChannel } = currentReservationDetail
      const updatedState = {
        firstName,
        lastName,
        emailAddress: email,
        salutation,
        phoneNumber,
        phoneCountryCode: phoneNumberLocale?.toLowerCase() as CountryCode,
        postalCode: postalCode || '',
        birthdayMonth,
        birthdayDay,
        agreedToVenueSmsMarketingOptIn: venueSmsMarketingEnabled,
        champagnePreferenceTags: champagnePreferenceTags || [],
        clientDietaryPreferenceTags: clientDietaryPreferenceTags || [],
        liquorPreferenceTags: liquorPreferenceTags || [],
        partyDietaryPreferenceTags: partyDietaryPreferenceTags || [],
        specialOccasionTags: specialOccasionTags || [],
        agreedToGdprDietaryOptIn: agreedToGdprDietaryOptin || false,
        customQuestionAnswer: customQuestionResponseNote || '',
        // there is a corner case when field is disabled but empty, we should allow to user to change it
        lockedFields: {
          ...formState.lockedFields,
          firstName: !!firstName,
          lastName: !!lastName,
          email: !!email,
          phoneNumber: !!phoneNumber,
        },
        durationPickerEnabled: isDurationPicked,
        duration: isDurationPicked ? duration : undefined,
        actual: {
          ...currentReservationDetail.actual,
          ...currentReservationDetail.bookingAccess,
        },
        ...(currentStep?.name === 'search' && { partySize: maxGuests, startTime: arrivalTime, startDate: date }),
        preferredMessagingChannel,
      }
      updateFormState(updatedState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, currentReservationDetail, updateFormState])

  useEffect(() => {
    if (isError || !token) {
      showErrorModal()
    }
  }, [isError, setActiveFlow, showErrorModal, token])

  useEffect(() => {
    if (hasPaymentError) {
      showErrorModal(
        formatMessage(reservationWidgetMessages.resPaymentFailedModalBody),
        formatMessage(reservationWidgetMessages.resPaymentFailedModalHeader),
        true
      )
    }
    // formatMessage dependency triggers use effect to be executed on each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPaymentError, showErrorModal])

  return (
    <Switch>
      <Route path={modifyPath.search.path}>
        <SearchAndAvailability shouldDisplayLoader={isLoading || !formState?.actual} />
      </Route>
      <Route path={modifyPath.upgrades.path}>
        <UpgradesPage />
      </Route>
      <Route path={modifyPath.checkout.path}>
        <CheckoutPage />
      </Route>
      <Redirect to={modifyPath.search.path} />
    </Switch>
  )
}
