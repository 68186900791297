import type { Field } from '@sevenrooms/core/form'
import { FirstNameForm, LastNameForm, PhoneNumberForm, EmailAddressForm, SalutationForm } from '../../../components'
import { useClientLogin, useWidgetSettings, useSalutationOption } from '../../../hooks'
import { SevenroomsGlobalClientDetails } from './SevenRoomsGlobalClientDetails'
import type { LockedFields } from '../../../store/reducers/getInitialReservationFormStateState'
import type { CheckoutForm } from '../Checkout.zod'

export interface UserContactDetailsProps {
  field: Field<CheckoutForm>
  lockedFields: LockedFields
  disabled: boolean
}

export function UserContactDetails({ field, disabled, lockedFields }: UserContactDetailsProps) {
  const { salutationType } = useWidgetSettings()
  const { salutationOptions } = useSalutationOption()
  const { provider } = useClientLogin()

  if (provider === 'sevenrooms') {
    return <SevenroomsGlobalClientDetails field={field} />
  }
  return (
    <>
      {salutationType !== 'Hidden' ? (
        <SalutationForm
          required={salutationType === 'Required'}
          salutationField={field.prop('salutation')}
          disabled={disabled}
          salutationOptions={salutationOptions}
        />
      ) : null}
      <FirstNameForm firstNameField={field.prop('firstName')} disabled={lockedFields.firstName || disabled} />
      <LastNameForm lastNameField={field.prop('lastName')} disabled={lockedFields.lastName || disabled} />
      <EmailAddressForm emailAddressField={field.prop('emailAddress')} disabled={lockedFields.email || disabled} />
      <PhoneNumberForm
        phoneNumberField={field.prop('phoneNumber')}
        phoneDialCodeField={field.prop('phoneDialCode')}
        phoneCountryCodeField={field.prop('phoneCountryCode')}
        disabled={lockedFields.phoneNumber || disabled}
      />
    </>
  )
}
